.collaboratorCard {
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 20px;
  background-color: rgb(194, 195, 205);
  display: flex;
  flex-direction: column;
}

.collabImageContainer {
  height: 170px;
}

.collabImageWrapper {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  overflow: hidden; /* Clip the image to the border-radius */
}

.collabImage {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid black;
}

.collabImage img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 61.9375em) {
  .collabImageContainer {
    height: auto;
  }

}