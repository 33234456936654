body {
  font-family: 'Source Sans Pro', sans-serif;
  /* font-family: 'Fira Sans', sans-serif; */
  background-color: #ebe7e7;
  /* font-family: -apple-system,".SFNSText-Bold", "Fira Sans", "San Francisco","Roboto","Segoe UI","Helvetica Neue","Lucida Grande",Arial,sans-serif; */
}

html {
  background: #fbfbfb;
}


mjx-utext {
  width: 100% !important;
}

.navbar-margin {
  margin: auto;
  font-size: 1.2em;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.2em;
}

.navbar-background {
  background-color: rgb(194, 195, 205) !important;
}

.navbar-light:hover .navbar-nav:hover .nav-link:hover {
  color: #C04024;
}

.activeNavItem {
  color: #C04024 !important; 
}

.profile {
  padding: 30px 10px;
  position: relative;
  margin-right: 20px;
  flex-direction: column;
}

.profileImage {
  border-radius: 50%;
  max-width: 175px;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.imageDiv {
  width: 50px;
}

a {
  color: #C04024;
  text-decoration: none;
}

a:hover {
  color: #C04024;
}

input[type=button]:focus, .btn-secondary:focus, .btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
} 

.contentContainer {
  padding-top: 70px !important;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}

.addressTag {
  color: #C04024;
  text-decoration: none;
  /* padding-top: 5px; */
  /* font-size: 18px; */
}

.addressTag:hover {
  color: #C04024;
}

.socialMediaLinks {
  display: flex;
  padding-top: 20px;
  white-space: nowrap;
  margin: 0 auto;
}

.paddingLeft10 {
  padding-left: 10px;
}

.profileName {
  font-size: 2em;
  font-weight: bold;
  margin-top: 10px;
  font-variant: small-caps;
}

.profileDesc {
  font-size: 1.1em;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.3em;
}

.linkedinColor, .linkedinColor:hover {
  color: #0a66c2;
  border-color: #6495ED;
}

.gmailColor, .gmailColor:hover {
  color: #E59866;
  /* border-color: #E59866; */
}

.scholarColor, .scholarColor:hover {
  color: #7C7C79;
  border-color: #7C7C79;
}

.infoSection {
  padding-top: 40px;
}

.curriculumVitae {
  color: #4F8C1C !important;
  border-color: #4F8C1C !important;
}

.sectionContainer {
  padding-top: 30px;
  align-items: baseline;
}

.headingBar {
  color: red;
  border-radius: 10px;
  height: 10px !important;
  opacity: 1 !important;
  margin: 0;
}

.headingFontSize {
  font-size: calc(1em + 1vw);
  font-variant: small-caps;
  font-weight: bolder;
  padding-bottom: 10px;
}

.headingFontSize span {
  padding-right: 10px;
  /* display: inline-block; */
}
.headingFontSize span:first-letter {
  font-size: 0.85em !important;
}

.dateColor {
  color: brown !important;
  font-size: 0.75em;
  border: 2px solid brown;
}

.alignBaseline {
  align-items: baseline;
}

.badgePadding {
  padding-right: 10px;
}

.boldColor {
  color: blue;
}

.contentPadding {
  padding-right: 10px;
}

.newsContainer {
  align-items: baseline;
  padding-bottom: 8px;
}

.newsContentPadding {
  padding-left: 10px;
}

@media only screen and (min-width: 1780px) {
  .dateContainer {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}

.nav-item {
  padding-left: 10px !important;
  font-variant: small-caps;
  font-weight: bolder;
}

.nameFontColor, .nameFontColor:hover {
  color: black;
}

.abstractMargin {
  margin-right: 10px;
}

.abstractContentPadding {
  padding-top: 10px;
}

.abstractContent {
  background-color: #ebe7e7;
  border: 1px solid brown;
}

.abstractBadge:hover {
  cursor: pointer;
}

.seminarDateMargin {
  margin-right: 10px;
}

.seminarsContainerPadding {
  padding-bottom: 10px;
}

.seminarsContentPadding {
  padding: 5px 0px;
}

.noteTitlePadding {
  padding-right: 10px;
}

.tableCellPadding {
  padding: 10px;
}

table {
  border-collapse: collapse;
}

tr { 
  border: solid;
  border-width: 1px 0;
}

.tablePadding {
  margin-top: 10px;
}

.eduElementPadding {
  padding-bottom: 10px;
}

.logoImagePadding {
  margin-right: 30px;
}

.blogDateMargin {
  margin-right: 10px;
}

.blogsPadding {
  padding-bottom: 20px;
}

.blogTitle {
  font-size: 1.5em;
  font-weight: bolder;
  font-variant: small-caps;
}

.blogTitle span {
  display: inline-block;
  padding-right: 10px;
}

.blogTitle span:first-letter {
  font-size: 0.85em !important;
}

.dropCap::first-letter {
  float: left;
  font-size: 5.6em;
  line-height: .68;
  font-weight: 100;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
  font-style: normal;
}

.blogContent {
  padding-top: 20px;
}

.videoPadding {
  padding: 10px 0px;
}

.blockquote {
  border-left: 5px solid red;
  margin: 0 0 24px;
  padding-left: 24px;
  color: #6e6e6e;
}

.blockquoteCite {
  font-size: 0.8em;
}

.blogSeparator {
  background: #bcb6b6;
  border: 0;
  height: 1px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 36px auto;
  width: 50%;
}

.topButton {
  display: none;
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
}

.subHeadingFontSize {
  font-size: 26px;
}

.breakWord {
  word-break: break-all;
}

.readMore {
  position: absolute;
  bottom: 10px;
}

.blogCardMargin {
  margin-bottom: 10px;
}

.readMoreBtn {
  color: brown;
  border: 2px solid brown;
}

.readMoreBtn:hover {
  background-color: brown;
  color: white;
}

.textWhite {
  color: white;
}

.card-text {
  margin-bottom: 3rem;
}

.cardBorder {
  border: 2px solid rgba(0,0,0,.125);
}

.socialLinks > * {
  padding-bottom: 5px;
}

.socialLink {
  margin-right: 25px;
}

.bioImage {
  border: 2px solid brown;
}

.seminarDate {
  height: fit-content;
  padding: 3px;
}

@media (min-width: 62.9375em) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 4em;
    align-self: flex-start;
  }

  .imageDiv {
    width: auto;
    height: auto;
  }

  .paddingTop30 {
    padding-top: 30px;
  }

  html {
    font-size: calc(0.25em + 0.7vw);
  }
}

@media (max-width: 61.9375em) {
  .profile {
    flex-direction: row;
    align-items: baseline;
  }

  .profile > * {
    margin-right: calc(0.1em + 2vw);
  }

  .profileName {
    /* font-size: 1.8em !important; */
    font-size: calc(1em + 3vw) !important;
  }

  .profileDesc {
    /* font-size: 1em !important; */
    font-size: calc(0.5em + 1.5vw) !important;
  }

  .socialLink {
    margin-left: 10px;
    margin-right: 10px !important;
  }

  .dropdown {
    right: 0;
    position: absolute;
  }

  .dropdownItem {
    width: max-content;
    align-items: center;
  }
  
  .dropdown-menu {
    padding: 5px;
    background-color: #ebe7e7;
    border: 2px solid brown;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
  }

  .dropdown-menu > * {
    padding: 6px
  }

  .bioImage {
    height: 50px !important;
    width: 50px !important;
  }

  .headingFontSize {
    font-size: calc(1.2em + 1vw);;
  }
  
  html {
    font-size: calc(0.5em + 1.5vw);
  }

  .newsDateContainer {
    width: calc(4em + 2vw) !important;
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .newsDate {
    width: max-content;
  }
}

.bioImage {
  height: 60px;
  width: 60px;
}

@media (min-width: 1024px) and (max-width:1399px)
{
  .talkDate {
    margin-right: 10px;
  }
}

@media (min-width: 2000px) {
  .talkDate {
    flex: 0 0 auto;
    width: 12.66666667%;
  }
}

.newsDateContainer {
  width: calc(3em + 2vw);
  flex: 0 0 auto;
  margin-right: 10px;
}

.dataBadge {
  color: var(--backgroundColor);
  border: 2px solid var(--backgroundColor);
}

.dataBadge:hover {
  background-color: var(--backgroundColor);
  color: white;
}

.cardContent {
  background-color: #ebe7e7;
  border: 2px solid brown;
}

.publication > * {
  margin-bottom: 1.2em;
}

.twitter {
  color: black;
}

.footer {
  background-color: rgb(194, 195, 205);
  justify-content: space-around;
  padding: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.connectBtn {
  border: 2px solid brown;
  font-size: 1.2em;
  font-variant: small-caps;
  font-weight: bolder;
  padding: 5px !important;
}

.rightBorder {
  border-right: 1px solid black;
}